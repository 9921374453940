import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/index.css'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

let app;

Vue.config.productionTip = false



firebase.auth().onAuthStateChanged(async user => {
  if (!app) {
    if (user) {
      await store.dispatch("setUser", user);
    } 
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});

