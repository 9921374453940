import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


// Your web app's Firebase configuration
// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyDkX2lUIzA9B9rPmizRNSk-d2uG1DofPnQ",
//     authDomain: "vuejs-a381e.firebaseapp.com",
//     projectId: "vuejs-a381e",
//     storageBucket: "vuejs-a381e.appspot.com",
//     messagingSenderId: "344964433866",
//     appId: "1:344964433866:web:c916ef238a82e098d77133"
//   };
  const firebaseConfig = {
    apiKey: "AIzaSyAz_v1JpYhaucqVTc5gT_RHuesq1N_mKR0",
    authDomain: "revz-60385.firebaseapp.com",
    projectId: "revz-60385",
    storageBucket: "revz-60385.appspot.com",
    messagingSenderId: "1065403734805",
    appId: "1:1065403734805:web:6cc39cdb32d8bdaa67271c",
    measurementId: "G-YRG9SR777C"
  };
// Initialize Firebase
//firebase.initializeApp(firebaseConfig);
export const db = firebase
    .initializeApp(firebaseConfig)
    .firestore()

const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }