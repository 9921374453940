<template>

    <div class="column currentDocContainer" style="width: 75%;height:100%;">

      <div v-if="selectedDocumentItem.downloadUrl == ''" class="empty-div" style="display:flex;align-items:center;justify-content:center;flex-direction:column;height:90vh">
        
        <img src="../assets/empty.svg" alt="empty state"  class="empty-image">

        <div  style="display:flex;align-items:center;justify-content:center;">

        <h3> No record selected, select a record to view its details</h3>
        </div>
      </div>

      <div v-else class="currentDoc">
        <h3 class="currentDocTitle" style="text-align: center;padding-top:5ch;">
          {{ selectedDocumentItem.fileName }}
        </h3>
        
      <div class="flex-container">
        <div>
          <h3 style="text-align: center;padding-top:5vh;" class="detailsColumn">
            <div>

          {{ selectedDocumentItem.user }}
            </div>
            <div class="rowDetail">
              Uploaded By

            </div>
          </h3>

          

        </div>

        <div>
          <h3 style="text-align: center;padding-top:5vh;" class="detailsColumn">
            <div>
              {{ DocUploadedOn }}
            </div>
            <div class="rowDetail">
              Uploaded On
            </div>
          </h3>

        

        </div>

      </div>

        <div style="display:flex;justify-content:center;padding-top:2vh;padding-bottom:5vh;">
          <div class="btn">
            <a :href="selectedDocumentItem.downloadUrl" target="_blank" rel="noreferrer noopener" style="text-decoration:none;color:white;">
              <h1>Print</h1>
            </a>
          </div>
        </div>

      </div>

    </div>
</template>

<style>
.detailsColumn {
  display: flex;
  flex-direction: column;
}

.rowDetail {
  font-size: .6em;
    color: grey;
    font-weight: normal;
    margin-top: 1.5vh
}
</style>

<script>
export default {
  props: ["selectedDocumentItem"],
  data() {
    return {
      
    };
  },
  computed: {

    DocUploadedOn() {
      let currentDate = new Date(this.selectedDocumentItem.uploadedOn*1000);
     
      return currentDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    },

  },
};
</script>

<style scoped>

.item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  /* height: 100px; */
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(141, 141, 141, 0.25);
}
/* 
.item-image {
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-right: 7px;
} */

.item-data {
  display: flex;
  flex-direction: column;
}

.item-name {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  /* line-height: 0px; */
  margin-top: 30px;
  /* height:100px; */
}

.item-type {
  padding: 0px;
  margin-top: 7px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
