<template>
      <img src="../assets/log-out.png" width="45" alt="">

</template>

<style>


</style>

<script>
export default {
 
  data() {
    return {
    };
  },
  methods: {
    
  },
};
</script>

<style scoped>



</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
