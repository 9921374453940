import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { db } from '../db'
import router from '@/router/index'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

Vue.use(Vuex)

const auth = firebase.auth();

export default new Vuex.Store({
  state: {
    notes: [],
    documents:[],
    currentDocument: null,
    isLoading: false,
    user: {
      
    }
},
mutations: {
    ...vuexfireMutations,
    setIsLoadingToFalse(state){
        state.isLoading = false;
    },
    setIsLoadingToTrue(state){
        state.isLoading = true;
    },
    setUser(state, user){
        state.user = user;
    }
},

actions: {

    setIsLoading({commit},to) {
        if(to)
            commit('setIsLoadingToTrue')
        else
            commit('setIsLoadingToFalse')
    },

    bindDocuments: firestoreAction(({ bindFirestoreRef, dispatch, state }) => {
        dispatch('setIsLoading',true)
        return bindFirestoreRef('documents', 
                db.collection('printing')
                .doc(state.user.uid)
                .collection('documents'))
                .then(
                    ()=> {
                        dispatch('setIsLoading',false)
                    }
                )
    }),

    // login firbase
    login({commit},payload){
        return new Promise((resolve,reject)=>{
            auth.signInWithEmailAndPassword(payload.email,payload.password)
            .then(
                response=>{
                    console.log(response.user.uid)
                    commit('setUser',response.user)
                    router.push('/');

                    resolve(response)

                }
            )
            .catch(
                error=>{
                    reject(error)
                }
            )
        })
    },

    // logout firebase
    logout({commit}){
        return new Promise((resolve,reject)=>{
            auth.signOut()
            .then(
                ()=>{
                    commit('setUser',{})
                    router.push('/login');
                    resolve()
                }
            )
            .catch(
                error=>{
                    reject(error)
                }
            )
        })
    },

    // set user
    setUser({commit},user){
        commit('setUser',user)
    }

},
  modules: {
  }
})
