<template>

  <div class="row">

    <div class="navbar-main">
      <img src="../assets/ic_stat_logo_tbg.png" style="padding:10px;" height="70%" alt="">

      <h1 class="navbar-text">Printing</h1>
      <div is="logout-button"
      style="cursor:pointer;padding:10px;"
      v-on:click.native="logout"
      >

      </div>
 
    </div>

    <div class="column left">


    <div v-if="IsLoading" style="display:flex;justify-content:center;">
      <div class="loadingio-spinner-rolling-zro3odea0eo">
        <div class="ldio-u9w2oqbr38">
          <div></div>
        </div>
      </div>
      </div>                   


    <div 
      style="height:90vh;overflow-y: scroll;"
    >

    <div v-if="!IsLoading && Documents.length == 0">
      <div style="display:flex;justify-content:center;align-items:center;height:100%;">
        <h3>No documents have been uploaded yet</h3>
      </div>
    </div>

    <div
    v-else
      is="document-list-item"
      v-on:click.native="selectDoc(document)"
      v-for="document in Documents"
      v-bind:key="document.id"
      :documentItem="document"
      :isSelected="isSelected(document)"
     ></div>


      </div>              

    </div>

    <div
    is="selected-document-item"
    :selectedDocumentItem="this.document"
    >

    </div>



  </div>

    
</template>

<script>
import DocumentListItem from "./DocumentListItem.vue";
import SelectedDocumentItem from "./SelectedDocumentItem.vue";
import LogoutButton from "./LogoutButton.vue"

import store  from "../store/index";
export default {
  name: "App",
  components: {
    "document-list-item": DocumentListItem,
    "selected-document-item": SelectedDocumentItem,
    "logout-button": LogoutButton
  },
  data() {
    return {

      document: {
        downloadUrl: "",
        fileExtension: "",
        fileName: "",
        fileSize: 0,
        id: "",
        printingPersonID: "",
        uploadedOn: 0,
        user: "",
      },
      //   document: {
      //   downloadUrl: "aasds",
      //   fileExtension: ".xlsx",
      //   fileName: "Outstanding Fee List Spring-2022.xlsx",
      //   fileSize: 5543,
      //   id: "121312",
      //   printingPersonID: "12312312",
      //   uploadedOn: 1648471997000,
      //   user: "20I-0437",
      // },

    };
  },
  computed: {

    Documents() {
      return store.state.documents.sort((a,b) => (a.uploadedOn > b.uploadedOn) ? 1 : ((b.uploadedOn > a.uploadedOn) ? -1 : 0)).reverse();
    },

    IsLoading() {
      return store.state.isLoading;
    },


  },
  methods: {
      isSelected(doc) {
      return this.document.id == doc.id;
    },
    selectDoc(doc) {
      this.document = doc;
    },
      // logout 
    logout() {
      store.dispatch("logout");
    },

  },
  created() {
    store.dispatch("bindDocuments");    

  },
};
</script>


