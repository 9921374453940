<template>
  <div class="item" :class="{ active: isSelected }">
    <div class="item-data">
      <span style="font-size: 1.5em">
        {{ documentItem.fileName }}
      </span>

      <span class="item-type">{{ documentItem.user }}</span>
    </div>
  </div>
</template>

<style></style>

<script>
export default {
  props: ["documentItem", "isSelected"],
  data() {
    return {
      isEditing: false,
    };
  },
  methods: {
    removeNote() {
      this.$emit("delete");
    },
    editNote() {
      this.isEditing = true;
    },
    updateNote() {
      this.isEditing = false;
      this.$emit("update");
    },
  },
};
</script>

<style scoped>
.item {
  cursor: pointer;
  display: flex;
  word-wrap: break-word;
  /* width: 25vw; */

  /* flex-direction: row; */
  padding: 10px;
  /* height: 100px; */

  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(141, 141, 141, 1);
}

.active {
  background: #0571f0;
  color: white;
}
/* 
.item-image {
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-top: 20px;
  margin-right: 7px;
} */

.item-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.item-name {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  /* line-height: 0px; */
  /* margin-top: 30px; */
  /* height:100px; */
}

.item-type {
  padding: 0px;
  margin-top: 7px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
