import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import firebase from 'firebase/compat/app';

import store from '../store/index'

import 'firebase/compat/auth';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      authRequired: true,
      title: "Home | Auxi"
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "Login | Auxi"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }
]

const router = new VueRouter({
  routes
})

const auth = firebase.auth();

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (auth.currentUser) {
      next();
    } else {
      next({
        path: '/login',
      });
    }
  } else {
    next();
  }
});

const DEFAULT_TITLE = 'Some Default Title';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});


// router.beforeEach((to, from, next) => {
//   // let authenticated = store.getters.isAuthenticated;
//   let authenticated = true
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (!authenticated) {
//       next({
//         name: "login",
//         query: { redirect: to.fullPath }
//       });
//     } else {
//       next();
//     }
//   } else {
//     // doesn't require auth, but if authenticated already and hitting login then go to overview
//     if (authenticated && to.name === "login") {
//       next({
//         name: "home"
//       });
//     }
//     next(); // make sure to always call next()!
//   }
// });

export default router
